<template>
  <div class="create-other">
    <div class="header-title">Feedback</div>
    <div class="feedback-info">
      <van-cell-group>
        <van-field
          type="textarea"
          v-model.trim="remark"
          label=""
          placeholder=""
          rows="3"
          autosize
          :disabled="visitInfo.isEdit == 0"
          show-word-limit
          @input="changeInput"
        />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import CreateMixins from './createMixins'
export default {
  name: 'Other',
  mixins: [CreateMixins],
  props: {
    visitInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      remark: ''
    }
  },
  methods: {
    changeInput() {
      console.log('remark', this.remark)
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        clearTimeout(this.timer)
        this.timer = null
        console.log('xxx',this.paramsUrl)
        // let channelVisitCreateInfo = {}
        // if (localStorage.getItem('channelVisitCreateInfo')) {
        //   channelVisitCreateInfo = JSON.parse(localStorage.getItem('channelVisitCreateInfo'))
        // }
        // channelVisitCreateInfo[this.paramsUrl.art_id].remark = this.remark
        // localStorage.setItem('channelVisitCreateInfo', JSON.stringify(channelVisitCreateInfo))
        this.$emit('changeRemark', this.remark)
      }, 500)
    }
  }
  // beforeDestroy() {
  //   console.log('beforeDestroy-other')
  //   console.log(this.$options.name)
  //   let channelVisitCreateInfo = {}
  //   if (localStorage.getItem('channelVisitCreateInfo')) {
  //     channelVisitCreateInfo = JSON.parse(localStorage.getItem('channelVisitCreateInfo'))
  //   }
  //   channelVisitCreateInfo = {
  //     ...channelVisitCreateInfo,
  //     remark: this.remark
  //   }
  //   localStorage.setItem('channelVisitCreateInfo', JSON.stringify(channelVisitCreateInfo))
  // }
}
</script>

<style lang="less" scoped>
.create-other {
  font-size: 12px;
  .header-title {
    font-size: 14px;
    font-weight: bold;
    padding: 0 10px 10px 10px;
  }
  .feedback-info {
    height: 100px;
    border-top: 1px solid #dce5f6;
    border-bottom: 1px solid #dce5f6;
  }
}
</style>
